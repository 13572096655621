html,body{
    font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
	color: #333;
}
/*
 * Page: Login & Register
 * ----------------------
 */
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
.login-logo a,
.register-logo a {
  color: #444;
}
.login-page,
.register-page {
  background: #d2d6de;
}
.login-box,
.register-box {
  width: 360px;
  margin: 7% auto;
}
@media (max-width: 768px) {
  .login-box,
  .register-box {
    width: 90%;
    margin-top: 20px;
  }
}
.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #777;
}
.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}
.social-auth-links {
  margin: 10px 0;
}

/* Social buttons */
.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-social > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.btn-social.btn-lg {
  padding-left: 61px;
}
.btn-social.btn-lg > :first-child {
  line-height: 45px;
  width: 45px;
  font-size: 1.8em;
}
.btn-social.btn-sm {
  padding-left: 38px;
}
.btn-social.btn-sm > :first-child {
  line-height: 28px;
  width: 28px;
  font-size: 1.4em;
}
.btn-social.btn-xs {
  padding-left: 30px;
}
.btn-social.btn-xs > :first-child {
  line-height: 20px;
  width: 20px;
  font-size: 1.2em;
}
.btn-social-icon {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  width: 34px;
  padding: 0;
}
.btn-social-icon > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.btn-social-icon.btn-lg {
  padding-left: 61px;
}
.btn-social-icon.btn-lg > :first-child {
  line-height: 45px;
  width: 45px;
  font-size: 1.8em;
}
.btn-social-icon.btn-sm {
  padding-left: 38px;
}
.btn-social-icon.btn-sm > :first-child {
  line-height: 28px;
  width: 28px;
  font-size: 1.4em;
}
.btn-social-icon.btn-xs {
  padding-left: 30px;
}
.btn-social-icon.btn-xs > :first-child {
  line-height: 20px;
  width: 20px;
  font-size: 1.2em;
}
.btn-social-icon > :first-child {
  border: none;
  text-align: center;
  width: 100%;
}
.btn-social-icon.btn-lg {
  height: 45px;
  width: 45px;
  padding-left: 0;
  padding-right: 0;
}
.btn-social-icon.btn-sm {
  height: 30px;
  width: 30px;
  padding-left: 0;
  padding-right: 0;
}
.btn-social-icon.btn-xs {
  height: 22px;
  width: 22px;
  padding-left: 0;
  padding-right: 0;
}
.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook.focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  color: #ffffff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  background-image: none;
}
.btn-facebook .badge {
  color: #3b5998;
  background-color: #ffffff;
}

.btn-google {
  color: #ffffff;
  background-color: #dd4b39;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-google:hover,
.btn-google:focus,
.btn-google.focus,
.btn-google:active,
.btn-google.active,
.open > .dropdown-toggle.btn-google {
  color: #ffffff;
  background-color: #c23321;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-google:active,
.btn-google.active,
.open > .dropdown-toggle.btn-google {
  background-image: none;
}
.btn-google .badge {
  color: #dd4b39;
  background-color: #ffffff;
}
/*------------*/